export const mockDataDevice = [
{
    "id": 1,
    "devId": "SDMS-123456",
    "devType": "MMS Device",
    "devTypecode": 1,
    "devName": "Lab Test MMS",
    "client": 1,
    "clientVerb": "Elmodis",
    "onlineIOT": 1,
    "onlineESR": 1,
    "onlineOV": 2,
    "Health": [{
      "status": 1,
      "cpu": 70,
      "memUsed": 123,
      "memMax": 1000,
      "diskUsed": 1288377,
      "diskMax": 233454325
    }],
    "Network": [{
      "status": 1,
      "wanIp": "129.123.234.21",
      "lanIp": "192.168.0.11",
      "gateway": "192.168.0.1",
      "dns1": "192.168.0.1",
      "dns2": "1.1.1.1",
      "ntp1": "192.168.0.1",
      "ntp2": "1.1.1.1"
    }],
    "Services": [{
      "sdms-watch": "running",
      "sdms-cmd": "running",
      "sdms-ch": "running",
      "sdms-diag": "running",
      "sdms-ele": "running",
      "sdms-pv": "running",
      "sdms-temp": "running"
    }],
    "Childs": [{
      4001: "Vibration Sensor",
      4502: "Vibration Sensor",
      4203: "Vibration Sensor",
      4204: "Vibration Sensor"  
    }],
    "Events": [{
      1: "11.02.2023 - Planned Maintenance",
      2: "12.12.2022 - Network Config Changed",
      3: "01.12.2022 - Manual Service",
      4: "12.06.2022 - Device Installation"  
    }],
    "Notes": [{
      1: "Sprawdzić czy działa"
    }],
    "Parent": [{
      "status": 1,
      "devId": 3332,
      "devName": "Router 221"
    }]
  },
];