import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";

const Dot = ({ value}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  if (value) {
    return (
        <span style={{ color: 'green' }}>●</span>
      );
  } else {
    return (
        <span style={{ color: 'red' }}>●</span>
      );
  }
  
};

export default Dot;