import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar, GridEventListener } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useEffect } from "react";
import axios from 'axios';
import * as React from 'react';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import {SafeAreaView, ScrollView, StyleSheet, Text, View} from 'react-native';
    

const Manager = () => { 
    const [data, setData] = useState([]);
    const [uds, setUDS] = useState([]);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const openInNewTab = url => {
      window.open(url, '_blank', 'noopener,noreferrer');
    };

    const RenderPercentage = (props) => {
      const { value } = props;
      var percentagecolor = '#00FF00';
      if (value > 50 && value < 80) {
        percentagecolor = '#FFA500';
      } else if (value > 79) {
        percentagecolor = '#FF0000';
      }
      return (
        <strong>
          <Typography color={percentagecolor}>{value}%</Typography>
        </strong>
      );
    };
    
    RenderPercentage.propTypes = {
      value: PropTypes.instanceOf(Number),
    };

    const Renderheader = (props) => {
      const { value } = props.colDef.headerName;
      console.log(props.colDef.headerName);
      return (
        <Text style={[
          {
            transform: [{rotate: '-75deg'}],
            position: "absolute",
            color: colors.grey[100],
            fontSize: '11px',
            textAlign: "center",
            marginLeft: "-10px",
          },
        ]}>{props.colDef.headerName}</Text>
      );
    };

    const RenderName = (props) => {
      const { value } = props;
      return (
        <strong>
          <Typography style={{cursor: 'pointer', fontSize: '12px',}} color={colors.orangeAccent[200]} onClick={e=>handleIDClick(props.id)}>{value}</Typography>
        </strong>
      );
    };

    const RenderMachine = (props) => {
      const { value } = props;
      const link = "https://portal.elmodis.com/"+props.row.client_slug+"/settings/machines/configuration/machine/"+props.row.machine;

      return (
        <strong>
          <Typography style={{cursor: 'alias', fontSize: '12px',}} color={colors.blueAccent[300]} onClick={() => openInNewTab(link)}>{value}</Typography>
        </strong>
      );
    };

    const RenderParent = (props) => {
      const { value } = props;
      return (
        <strong>
          <Typography color={colors.orangeAccent[100]} onClick={e=>handleIDClick(props.row.parent)}>{props.row.parent_name}</Typography>
        </strong>
      );
    };

    const RenderUDS = (props) => {
      const { value } = props;
      return (
        <div className="gridselect">
          <select id={props.row.id} onChange={e=>handleUDSchange(props.row.id, e.target.value)} defaultValue={props.row.uds}>
            {uds.map((uds, i) =>
            <option value={uds.id}>{uds.name}</option>)}
          </select>
        </div>
      );
    };

    const RenderLA = (props) => {
      const { value } = props;
      return (
        <div className="gridselect">
          <select id={props.row.id} onChange={e=>handleUDSchange(props.row.id, e.target.value)} defaultValue={props.row.uds}>
            {uds.map((uds, i) =>
            <option value={uds.id}>{uds.name}</option>)}
          </select>
        </div>
      );
    };
    
    const handleUDSchange = async (id, uds) => {
      const obj = {};
      obj.action = 3;
      obj.id = id;
      obj.uds = uds;
      const request = await fetch('https://devices.elmodis.com/api/uds', {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
      })
    };

    let navigate = useNavigate(); 
    const handleIDClick = (params) => {
      let path = `/device/${params}`; 
      navigate(path);
    };
  
        
        const getDevices = async () => {
            await axios.get("https://devices.elmodis.com/api/devices").then((res) => {
            setData(res.data);
            })
        }
    
        useEffect(() => {
            getDevices();
        }, []);
        const getUDS = async () => {
          await axios.get("https://devices.elmodis.com/api/uds").then((res) => {
          setUDS(res.data);
          })
      }
  
      useEffect(() => {
          getUDS();
      }, []);
        
        const columns = [
          {
            field: "device_type",
            headerName: "Type",
            hide: true,
          },
          {
            field: "client_name",
            headerName: "Client",
            flex: 0.8,
            cellClassName: "name-column--cell",
          },
          {
            field: "parent",
            headerName: "Parent",
            renderCell: RenderParent,
            flex: 1,
            cellClassName: "name-column--cell",
          },
          {
            field: "machine_name",
            headerName: "Machine",
            renderCell: RenderMachine,
            flex: 1,
            cellClassName: "name-column--cell",
          },
          {
            field: "machine_id",
            headerName: "Machine ID",
            flex: 0.4,
            cellClassName: "name-column--cell",
            align: "center",
          },
          {
            field: "uds_name",
            headerName: "User Defined Status",
            hide: true,
            renderCell: RenderUDS,
            headerAlign: "left",
            align: "left",
          },
          {
            field: "devid",
            headerName: "Device ID",
            renderCell: RenderName,
            flex: 1,
            cellClassName: "name-column--cell",
          },
          {
            field: "name",
            headerName: "Name",
            renderCell: RenderName,
            flex: 2,
            cellClassName: "name-column--cell",
          },
          {
            field: "status",
            headerName: "Status",
            type: "boolean",
            headerAlign: "left",
            minWidth: 40,
            maxWidth: 40,
            renderHeader: Renderheader,
            align: "center",
          },
          {
            field: "online",
            headerName: "Online",
            type: "boolean",
            headerAlign: "left",
            minWidth: 40,
            maxWidth: 40,
            renderHeader: Renderheader,
            align: "center",
          },
          {
            field: "lastIOTactivity",
            headerName: "Last Active",
            type: "date",
            hide: true,
            headerAlign: "left",
            align: "left",
          },
          {
            field: "monitoring",
            headerName: "Monitoring",
            type: "boolean",
            headerAlign: "left",
            minWidth: 40,
            maxWidth: 40,
            renderHeader: Renderheader,
            align: "center",
          },
          {
            field: "hubmonitor",
            headerName: "IOTHub",
            type: "boolean",
            headerAlign: "left",
            minWidth: 40,
            maxWidth: 40,
            renderHeader: Renderheader,
            align: "center",
          },
          {
            field: "hardware",
            headerName: "Hardware",
            type: "boolean",
            headerAlign: "left",
            minWidth: 40,
            maxWidth: 40,
            renderHeader: Renderheader,
            align: "center",
          },
          {
            field: "software",
            headerName: "Software",
            type: "boolean",
            headerAlign: "left",
            minWidth: 40,
            maxWidth: 40,
            renderHeader: Renderheader,
            align: "center",
          },
          {
            field: "network",
            headerName: "Network",
            type: "boolean",
            headerAlign: "left",
            minWidth: 40,
            maxWidth: 40,
            renderHeader: Renderheader,
            align: "center",
          },
          {
            field: "processing",
            headerName: "CPU",
            renderCell: RenderPercentage,
            headerAlign: "left",
            align: "center",
          },
          {
            field: "memory",
            headerName: "Memory",
            renderCell: RenderPercentage,
            headerAlign: "left",
            align: "center",
          },
          {
            field: "data",
            headerName: "Storage",
            renderCell: RenderPercentage,
            headerAlign: "left",
            align: "center",
          },
          
        ];
      
        return (
          <Box m="20px">
            <Header title="DEVICES" subtitle="Edge Devices" />
            <Box
              m="40px 0 0 0"
              height="80vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.orangeAccent[300],
                },
                "& .dg-header": {
                  backgroundColor: "#ff0000",
                  borderBottom: "none",
                  height: "400px",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.grey[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.grey[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                  },
              }}
            >
              <DataGrid {...data} rows={data} columns={columns} components={{ Toolbar: GridToolbar }} headerHeight={100}/>
            </Box>
          </Box>
        );
}

export default Manager