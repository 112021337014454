import { Box, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import * as React from 'react';
import { ColorModeContext, tokens } from "../theme";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import {useState, useRef} from 'react';
import { useEffect } from "preact/hooks";

function ModSearch ({action, mode}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [reply, setReply] = useState([])
    const [holder, setHolder] = useState('')
    const ref = useRef(null);
    const getReply = async (query) => {
        console.log(query);
        const request = await fetch('https://devices.elmodis.com/api/search', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(query)
        })
        await setReply(await request.json());
    }
    function handleClick(device) {
        const element = ref.current;
        element.value = device.name;
        setReply([]);
        action(device);
    }

    return (
        <Box>
        <Box
            display="flex"
            backgroundColor={colors.primary[400]}
            borderRadius="3px"
            >
            <InputBase inputRef={ref} sx={{ ml: 2, flex: 1 }} placeholder="Search" onChange={e=>getReply(e.target.value)} />
            <IconButton type="button" sx={{ p: 1 }}>
                <SearchIcon />
            </IconButton>
        </Box>
        {reply.length > 0 && <div className="fastsearch"> 
            <Box
            display="grid"
            gridTemplateColumns="repeat(10, 1fr)"
            gridAutoRows="30px"
            gap="2px"
            >
                <Box
                gridColumn="span 1"
                backgroundColor={colors.primary[400]}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                >ID</Box>
                <Box
                gridColumn="span 4"
                backgroundColor={colors.primary[400]}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                >Device ID</Box>
                <Box
                gridColumn="span 4"
                backgroundColor={colors.primary[400]}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                >Device Name</Box>
                <Box
                gridColumn="span 1"
                backgroundColor={colors.primary[400]}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                >Machine ID</Box>
            </Box>
             {reply.map((reply, i) => 
            <Box
            display="grid"
            gridTemplateColumns="repeat(10, 1fr)"
            gridAutoRows="30px"
            gap="2px"
            className="fastsearch-inner"
            onClick={event => handleClick(reply)}
            key={reply.devid}
            >
                <Box
                gridColumn="span 1"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                >{reply.id}</Box>
                <Box
                gridColumn="span 4"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                >{reply.devid}</Box>
                <Box
                gridColumn="span 4"
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="center"
                >{reply.name}</Box>
                <Box
                gridColumn="span 1"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                >{reply.machine}</Box>
            </Box>        
            )}
            </div>
        }
        </Box>
        )
}

export default ModSearch;