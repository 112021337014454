import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar, GridEventListener } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useEffect } from "react";
import axios from 'axios';
import * as React from 'react';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ModSearch from "../../components/ModSearch";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ErrMsg from "../../components/ErrMsg";
    

const NetDeviceManager = () => { 
    const [data, setData] = useState([]);
    const [devTypes, setDevTypes] = useState([]);
    const [clients, setClients] = useState([]);
    const [uds, setUDS] = useState([]);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = React.useState(false);
    const [AlertName, setAlertName] = React.useState(false);
    const [FormError, setFormError] = React.useState(false);
    const [FormType, setFormType] = React.useState();
    const [FormName, setFormName] = React.useState();
    const [FormInfo, setFormInfo] = React.useState();
    const [FormClient, setFormClient] = React.useState();
    const [FormParent, setFormParent] = React.useState();
    const [reload, setReload] = React.useState(false);
    const [newParent, setNP] = React.useState();
    

    const RenderPercentage = (props) => {
      const { value } = props;
      var percentagecolor = '#00FF00';
      if (value > 50 && value < 80) {
        percentagecolor = '#FFA500';
      } else if (value > 79) {
        percentagecolor = '#FF0000';
      }
      return (
        <strong>
          <Typography color={percentagecolor}>{value}%</Typography>
        </strong>
      );
    };
    
    RenderPercentage.propTypes = {
      value: PropTypes.instanceOf(Number),
    }; 

    const RenderName = (props) => {
      const { value } = props;
      return (
        <strong>
          <Typography onClick={e=>handleIDClick(props.id)}>{value}</Typography>
        </strong>
      );
    };

    function updateParent (searchData) {
      setNP(searchData.id);
      setReload(true);
    };

    
    const handleFormSubmit = async () => {
      var values = {};
      values['type'] = FormType;
      values['name'] = FormName;
      values['client'] = FormClient;
      values['parent'] = newParent;
      const request = await fetch('https://devices.elmodis.com/api/nda', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
      })
      const result = await request.json();
      if(result.hasOwnProperty('Error')){
        setFormError(true);
        setFormInfo(result.Message);
      } else {
        getDevices();
        handleClose();
      }
    };

    const getTypes = async () => {
      await axios.get("https://devices.elmodis.com/api/devtypes").then((res) => {
      setDevTypes(res.data);
      })
    }

    const getClients = async () => {
      await axios.get("https://devices.elmodis.com/api/clients").then((res) => {
      setClients(res.data);
      })
    }

    const handleClose = () => {
      setOpen(false);
    };

    const RenderParent = (props) => {
      const { value } = props;
      return (
        <strong>
          <Typography onClick={e=>handleIDClick(props.row.parent)}>{props.row.parent_name}</Typography>
        </strong>
      );
    };

    const RenderUDS = (props) => {
      const { value } = props;
      return (
        <div className="gridselect">
          <select id={props.row.id} onChange={e=>handleUDSchange(props.row.id, e.target.value)} defaultValue={props.row.uds}>
            {uds.map((uds, i) =>
            <option value={uds.id}>{uds.name}</option>)}
          </select>
        </div>
      );
    };
    
    const handleUDSchange = async (id, uds) => {
      const obj = {};
      obj.action = 3;
      obj.id = id;
      obj.uds = uds;
      const request = await fetch('https://devices.elmodis.com/api/uds', {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
      })
    };

    function handleNewDevice() {
      setFormInfo('The Unique ID of the device will be assigned based on its type.')
      setFormError(false);
      setFormType();
      setFormName();
      setFormClient();
      setFormParent();
      setNP();
      setOpen(true);
      
    };

    let navigate = useNavigate(); 
    const handleIDClick = (params) => {
      let path = `/device/${params}`; 
      navigate(path);
    };
  
        
    const getDevices = async () => {
        await axios.get("https://devices.elmodis.com/api/netdevices").then((res) => {
        setData(res.data);
        })
    }

    useEffect(() => {
        getDevices();
        getTypes();
        getClients();
    }, []);

    const getUDS = async () => {
      await axios.get("https://devices.elmodis.com/api/uds").then((res) => {
      setUDS(res.data);
      })
    }
  
    useEffect(() => {
        getUDS();
    }, []);
        
        const columns = [
          {
            field: "device_type",
            headerName: "Type",
          },
          {
            field: "devid",
            headerName: "Device ID",
            renderCell: RenderName,
            flex: 1,
            cellClassName: "name-column--cell",
          },
          {
            field: "name",
            headerName: "Name",
            flex: 1,
            cellClassName: "name-column--cell",
          },
          {
            field: "parent",
            headerName: "Parent",
            renderCell: RenderParent,
            flex: 1,
            cellClassName: "name-column--cell",
          },
          {
            field: "client_name",
            headerName: "Client",
            flex: 1,
            cellClassName: "name-column--cell",
          },
          {
            field: "struct_name",
            headerName: "Structure",
            flex: 1,
            cellClassName: "name-column--cell",
          },
          {
            field: "uds_name",
            headerName: "User Defined Status",
            renderCell: RenderUDS,
            headerAlign: "left",
            align: "left",
          }     
        ];
      
        return (
          <Box m="20px">
            <Header title="Network Devices" />
            <Box>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
              onClick={() => { handleNewDevice() }}
            >            
            Add Device
            </Button>
            </Box>
            <Box
              m="40px 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.orangeAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.grey[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.grey[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                  },
              }}
            >
              <DataGrid {...data} rows={data} columns={columns} components={{ Toolbar: GridToolbar }} />
            </Box>
            <Dialog open={open} onClose={handleClose}>
            <DialogTitle><Typography
        variant="h4"
        color={colors.orangeAccent[600]}
        fontWeight="bold"
      >Add Device</Typography></DialogTitle>
            <DialogContent>
              <ErrMsg error={FormError} text={FormInfo} />
            <TextField
            autoFocus
            margin="dense"
            value={FormName}
            onChange={(e) => setFormName(e.target.value)}
            label="Device Name"
            type="text"
            fullWidth
            variant="standard"
            />
            <FormControl fullWidth sx={{ m: "5PX 0 5px 0" }}>
            <InputLabel id="devType-label">Device Type</InputLabel>
            <Select
              labelId="devType-label"
              id="devType-select"
              value={FormType}
              label="Device Type:"
              onChange={(e) => setFormType(e.target.value)}
              sx={{ m: "0 0 5px 0" }}
            >
              {devTypes.map((dt, i) => 
              i > 3 &&
              <MenuItem value={dt.id}>{dt.name}</MenuItem>
              )}
            </Select>
            </FormControl>
            Choose Parent: <ModSearch action={updateParent} />
            <FormControl fullWidth sx={{ m: "5PX 0 5px 0" }}>
            <InputLabel id="client-label">Client</InputLabel>
            <Select
              labelId="client-label"
              id="client-select"
              value={FormClient}
              label="Client:"
              onChange={(e) => setFormClient(e.target.value)}
              sx={{ m: "0 0 5px 0"}}
            >
              {clients.map((dt, i) => 
              i > 3 &&
              <MenuItem value={dt.pid}>{dt.name}</MenuItem>
              )}
            </Select>
            </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleFormSubmit} sx={{
                    backgroundColor: colors.orangeAccent[600],
                    color: colors.grey[100],
                    fontSize: "12px",
                    fontWeight: "bold",
                    padding: "5px 5px",
                  }}>New Device</Button>
              <Button onClick={handleClose} sx={{
                    backgroundColor: colors.blueAccent[600],
                    color: colors.grey[100],
                    fontSize: "12px",
                    fontWeight: "bold",
                    padding: "5px 5px",
                  }}>Cancel</Button>
            </DialogActions>
            </Dialog>
          </Box>
        );
}

export default NetDeviceManager