import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import { Link } from "react-router-dom";
import { hover } from "@testing-library/user-event/dist/hover";
import {useEffect, useState} from 'react';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ModSearch from "./ModSearch";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const ChildDevices = ({inData, setPageRefresh}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const childs = inData.child_arr;
  const parent = inData.parent_arr;
  const [reload, setReload] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [newParent, setNP] = React.useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleUpdate = () => {
    setOpen(false);
    console.log('test');
    if (reload) {
      setParent();
    }
  };

  const setParent = async (np) => {
    var FormData = require('form-data');
    var data = new FormData();
    data.append('np', newParent);
    data.append('id', inData.id);
    console.log(data);
    await axios.post('https://devices.elmodis.com/api/np', data).then((res) => {
      setPageRefresh(true);
    })
}

  function renderChilds() {
    if (childs.length === 0) {
       return (
          <Typography
                sx={{ color: colors.orangeAccent[600] }}
              >No childs</Typography>
        )
    } else {
      return (<Typography
        sx={{ color: colors.orangeAccent[600] }}
      >{childs.map((child, i) =>
        <div><Link style={{color: colors.orangeAccent[200], textDecoration: 'none'}} to={"/device/" + child.id}>- {child.name + ' (' + child.machine + ')'}</Link></div>)}
        </Typography>
      )
    }
  }
  function updateParent (searchData) {
    setNP(searchData.id);
    setReload(true);
  }

  let navigate = useNavigate(); 
    const handleIDClick = (params) => {
      let path = `/device/${params}`; 
      navigate(path);
    };

  return (
    <Box width="100%" m="0 20px" justifyContent="flex-start">
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.grey[100] }}
          >
          <AccountTreeOutlinedIcon sx={{ color: colors.orangeAccent[600], fontSize: "26px" }} /> Network Relations
          </Typography>
        </Box>
        <Box marginRight="40px"
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end">
        <Button variant="outlined" onClick={handleClickOpen} sx={{
                backgroundColor: colors.orangeAccent[700],
                color: colors.grey[100],
                fontSize: "10px",
                fontWeight: "bold",
                padding: "5px 5px",
              }}>
        Chage Device Parent
      </Button>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-start" mt="2px">
        <Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Change Device Parent</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Search for the device that is to become the new parent, then click update button.
          </DialogContentText>
          <ModSearch action={updateParent} />
        </DialogContent>
        <DialogActions>
          <Button sx={{
                backgroundColor: colors.orangeAccent[700],
                color: colors.grey[100],
                fontSize: "10px",
                fontWeight: "bold",
                padding: "5px 5px",
              }} onClick={handleClose}>Cancel</Button>
          <Button sx={{
                backgroundColor: colors.orangeAccent[700],
                color: colors.grey[100],
                fontSize: "10px",
                fontWeight: "bold",
                padding: "5px 5px",
              }} onClick={handleUpdate}>Update</Button>
        </DialogActions>
      </Dialog>
        </Typography>
        <Typography
          variant="h5"
          fontStyle="italic"
          sx={{ color: colors.orangeAccent[600] }}
        >
        </Typography>
      </Box>
      <Box mt="10px">
        Parent Device:
      </Box>
      <Box display="flex" alignItems="left" justifyContent="left" textAlign="left" mt="4px">
        <Typography
            sx={{ color: colors.orangeAccent[600] }}
          >
            {inData.parent != null ? (
        <Link style={{color: colors.orangeAccent[200], textDecoration: 'none'}} to={"/device/" + parent.id}>- {parent.name + ' (' + parent.machine + ')'}</Link>
      ) : (
        <div>Top Tier Device</div>
      ) }
          </Typography>
        </Box>
      <Box mt="10px">
        Child Devices:
      </Box>
        <Box display="flex" alignItems="left" justifyContent="left" textAlign="left" mt="4px">
        <Typography
            sx={{ color: colors.orangeAccent[600] }}
          >
        {renderChilds()}
          </Typography>
        </Box>

    </Box>
  );
};

export default ChildDevices;