import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar, GridEventListener } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useEffect } from "react";
import axios from 'axios';
import * as React from 'react';
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { mockDataDevice } from "../../data/mockDevice";
import NetworkInfo from "../../components/NetworkInfo";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import StatBox from "../../components/StatBox";
import MemoryIcon from '@mui/icons-material/Memory';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import FilterDramaOutlinedIcon from '@mui/icons-material/FilterDramaOutlined';
import OnlineBox from "../../components/OnlineBox";
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import ProcesessStats from "../../components/ProcessStats";
import ChildDevices from "../../components/ChildDevices";
import DeviceEvents from "../../components/DeviceEvents";
import DeviceNotes from "../../components/DeviceNotes";
import { positions } from '@mui/system';
import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import ContactEmergencyOutlinedIcon from '@mui/icons-material/ContactEmergencyOutlined';
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';
import PropTypes from 'prop-types';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ClientChanger from "../../components/ClientChange";
import DevHead from "../../components/DevHead";


const Device = () => {
  const id = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [deviceInfo, setDevice] = useState([]);
  const [udsBox, setUDSBox] = useState();
  const [uds, setUDS] = useState([]);
  const [clients, setClients] = useState([]);
  const [reload, setReload] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const device = getType();
  const deviceID = useLocation().pathname.split("/")[2];

  const loadPage = async () => {
    console.log('loading');
    await getUDS();
    await getClients();
    await getDevice();
}
  const getDevice = async () => {
      var FormData = require('form-data');
      var data = new FormData();
      data.append('devid', deviceID);
      await axios.post('https://devices.elmodis.com/api/device', data).then((res) => {
      //console.log(res.data);
      setDevice(res.data);
      setLoading(false);
      })
  }
  const getUDS = async () => {
    await axios.get("https://devices.elmodis.com/api/uds").then((res) => {
    setUDS(res.data);
    })
  }

  const getClients = async () => {
    await axios.get("https://devices.elmodis.com/api/clients").then((res) => {
    setClients(res.data);
    })
  }

  function getDevid() {
    if (!isNaN(deviceID)){
      return deviceID;
    }
  }

   function getType() {
      const dev = mockDataDevice.map(content =>{
        return content;
      })
      return dev[0];
  }

  const handleUDSchange = async (id, uds) => {
    const obj = {};
    obj.action = 3;
    obj.id = id;
    obj.uds = uds;
    const request = await fetch('https://devices.elmodis.com/api/uds', {
    method: 'POST',
    headers: {
    'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
    })
  };

  const RenderUDS = (props) => {
    return (
      <div className="udsselect">
        <select id={'udschanger'+deviceInfo.id} onChange={e=>handleUDSchange(deviceInfo.id, e.target.value)} defaultValue={deviceInfo.uds}>
          {uds.map((uds, i) =>
          <option value={uds.id}>{uds.name}</option>)}
        </select>
      </div>
    );
  };

useEffect(() => {
  loadPage(true);
},[])

useEffect(() => {
  if (reload) {
    setReload(false);
    setLoading(true);
  }
},[reload])
  
  if (isLoading) {
    return (
      <Box m="20px">
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Loading" type="Please Wait" />
        </Box>
        </Box>
    )
  } else {
    return (
      <Box m="20px">
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <DevHead devdata={deviceInfo} />
        </Box>
        <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="60px"
        gap="20px"
        >
          <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="left"
          pl="20px"
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ color: colors.orangeAccent[600] }}
            >
            <FingerprintOutlinedIcon />
            </Typography>&nbsp;Machine ID:&nbsp;<Typography
              fontWeight="bold"
              sx={{ color: colors.orangeAccent[600] }}
            >{deviceInfo.machine_id != null ? deviceInfo.machine_name + ' (' + deviceInfo.machine_id + ')' : 'Not Set'}</Typography>
          </Box>
          <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="left"
          pl="20px"
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ color: colors.orangeAccent[600] }}
            >
            <CorporateFareOutlinedIcon />
            </Typography>&nbsp;Organization:&nbsp;<Typography
              fontWeight="bold"
              sx={{ color: colors.orangeAccent[600] }}
            ><ClientChanger devid={deviceInfo.id} clients={clients} current={deviceInfo.client} /></Typography>
          </Box>
          <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="left"
          pl="20px"
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ color: colors.orangeAccent[600] }}
            >
            <ContactEmergencyOutlinedIcon />
            </Typography>&nbsp;User Defined Status:&nbsp;<Typography
              fontWeight="bold"
              sx={{ color: colors.orangeAccent[600] }}
            >{RenderUDS()}</Typography>
          </Box>
          <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="left"
          pl="20px"
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ color: colors.orangeAccent[600] }}
            >
            <RotateLeftOutlinedIcon />
            </Typography>&nbsp;Request Refresh:&nbsp;<Typography
              fontWeight="bold"
              sx={{ color: colors.orangeAccent[600] }}
            ></Typography>
          </Box>
          {/* ROW 1 */}
          <Box
            gridColumn="span 3"
            gridRow="span 4"
            backgroundColor={colors.primary[400]}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            pt="20px"
          >
          <NetworkInfo netData={deviceInfo.network_arr} parent={deviceInfo.id} reCharge={setReload} />
          </Box>
          <Box
          gridColumn="span 3"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="CPU Usage"
            subtitle="CPU Usage"
            progress={deviceInfo.processing / 100}
            increase={deviceInfo.processing + '%'}
            icon={
              <MemoryIcon
                sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="Memory Usage"
            subtitle="Memory Usage"
            progress={deviceInfo.memory / 100}
            increase={deviceInfo.memory + '%'}
            icon={
              <MemoryIcon
                sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="Disk Usage"
            subtitle="Disk Usage"
            progress={deviceInfo.data / 100}
            increase={deviceInfo.data + '%'}
            icon={
              <SaveOutlinedIcon
                sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <OnlineBox
            title="Azure IOT Status"
            subtitle={'Last Report: ' + deviceInfo.lastIOTactivity}
            status={deviceInfo.online}
            icon={
              <FilterDramaOutlinedIcon
                sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <OnlineBox
            title="ESR Connection Status"
            subtitle="Connection to ESR Server"
            status={deviceInfo.network}
            icon={
              <DnsOutlinedIcon
                sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <OnlineBox
            title="Overseer Monitoring Status"
            subtitle="Connection to Overseer Server"
            status={deviceInfo.monitoring}
            icon={
              <DnsOutlinedIcon
                sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        {/* ROW 2 */}

        <Box
            gridColumn="span 3"
            gridRow="span 4"
            backgroundColor={colors.primary[400]}
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            alignContent="stretch"
            justifyContent="flex-start"
            pt="20px"
          >
          <ProcesessStats procData={device.Services} />
          </Box>
          <Box
            gridColumn="span 3"
            gridRow="span 4"
            backgroundColor={colors.primary[400]}
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            alignContent="stretch"
            justifyContent="flex-start"
            pt="20px"
            style={{
              overflow: "hidden",
              overflowY: "scroll",
            }}
          >
          <ChildDevices inData={deviceInfo} setPageRefresh={setReload}/>
          </Box>
          <Box
            gridColumn="span 3"
            gridRow="span 4"
            backgroundColor={colors.primary[400]}
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            alignContent="stretch"
            justifyContent="flex-start"
            pt="20px"
          >
          <DeviceEvents eventsData={deviceInfo.events_arr}  />
          </Box>
          <Box
            gridColumn="span 3"
            gridRow="span 4"
            backgroundColor={colors.primary[400]}
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            alignContent="stretch"
            justifyContent="flex-start"
            pt="20px"
          >
          <DeviceNotes deviceInfo={deviceInfo} setPageRefresh={setReload} />
          </Box>
        </Box>
      </Box>
    );
          }
};

export default Device;