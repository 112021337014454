import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';

const ProcesessStats = ({ procData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const device = procData[0];
  return (
    <Box width="100%" m="0 30px" justifyContent="flex-start">
      <Box display="flex" justifyContent="flex-start">
        <Box>
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.grey[100] }}
          >
          <AccountTreeOutlinedIcon sx={{ color: colors.orangeAccent[600], fontSize: "26px" }} /> Running Services
          </Typography>
        </Box>
        <Box>
        
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-start" mt="2px">
        <Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>
          Synced <br />
        </Typography>
        <Typography
          variant="h5"
          fontStyle="italic"
          sx={{ color: colors.orangeAccent[600] }}
        >
        </Typography>
      </Box>
      <Box
        display="grid"
        m="20px 0 0 0"
        gridTemplateColumns="repeat(12, 1fr)"
        gap="10px"
        >
        <Box gridColumn="span 4" display="flex" alignItems="right" justifyContent="right" textAlign="right">
        <Typography
            sx={{ color: colors.orangeAccent[600] }}
          >
        {Object.keys(device).map(key => {
        return (
              <div>
                {key}:
              </div>
        );
        })}
          </Typography>
        </Box>
        <Box gridColumn="span 8" display="flex" alignItems="left" justifyContent="left">
        <Typography
            fontStyle="italic"
            sx={{ color: colors.grey[100] }}
          >
          {Object.keys(device).map(key => {
        return (
              <div>
                {device[key]}
              </div>
        );
        })}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ProcesessStats;