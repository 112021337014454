import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar, GridEventListener } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useEffect } from "react";
import axios from 'axios';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DeleteForeverOutlined from "@mui/icons-material/DeleteForeverOutlined";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
    

const UDSManager = () => { 
    const [data, setData] = useState([]);

    let navigate = useNavigate(); 
    const handleRowClick = (params) => {
      handleEditDevice(params.row.id, params.row.name, params.row.description)
    };

    const Renderbin = (props) => {
      const { hasFocus, value } = props;
      const buttonElement = React.useRef(null);
      const rippleRef = React.useRef(null);
      
      React.useLayoutEffect(() => {
        if (hasFocus) {
          const input = buttonElement.current?.querySelector('input');
          input?.focus();
        } else if (rippleRef.current) {
          // Only available in @mui/material v5.4.1 or later
          rippleRef.current.stop({});
        }
      }, [hasFocus]);
      
      return (
        <strong>
          <Button
            component="button"
            ref={buttonElement}
            touchRippleRef={rippleRef}
            variant="contained"
            size="small"
            style={{ marginLeft: 6 }}
            // Remove button from tab sequence when cell does not have focus
            tabIndex={hasFocus ? 0 : -1}
            onKeyDown={(event) => {
              if (event.key === ' ') {
                // Prevent key navigation when focus is on button
                event.stopPropagation();
              }
            }}
          >
          <DeleteForeverOutlined /> Delete
          </Button>
        </strong>
      );
    };
    
    Renderbin.propTypes = {
      hasFocus: PropTypes.bool.isRequired,
      value: PropTypes.instanceOf(Number),
    };

    const [open, setOpen] = React.useState(false);
    const [AlertName, setAlertName] = React.useState(false);
    const [FormID, setFormID] = React.useState(false);
    const [FormType, setFormType] = React.useState(false);
    const [FormDesc, setFormDesc] = React.useState(false);
    const [FormName, setFormName] = React.useState(false);
  

    function handleNewDevice() {
      setAlertName("Add UDS");
      setFormType(1);
      setFormID(0);
      setFormDesc("");
      setFormName("");
      setOpen(true);
    };

    function handleEditDevice(id, name, description) {
      setAlertName("Edit UDS");
      setFormType(2);
      setFormID(id);
      setFormDesc(description);
      setFormName(name);
      setOpen(true);
    };

    function handleSubmit() {
      console.log(FormName);
      console.log(FormDesc);
      console.log(FormID);
    }

    const handleClose = () => {
      setOpen(false);
    };

    const handleFormSubmit = async () => {
      var values = {};
      values['action'] = FormType;
      values['name'] = FormName;
      values['desc'] = FormDesc;
      values['id'] = FormID;
      console.log(JSON.stringify(values));

      const request = await fetch('https://devices.elmodis.com/api/uds', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
      })
      const result = await request.json();
      if(result.hasOwnProperty('Error')){

      } else {
        getTypes();
        handleClose();
      }
    };
  
        
        const getTypes = async () => {
            await axios.get("https://devices.elmodis.com/api/uds").then((res) => {
            setData(res.data);
            })
        }
    
        useEffect(() => {
          getTypes();
        }, []);
        
        const theme = useTheme();
        const colors = tokens(theme.palette.mode);
        const columns = [
          {
            field: "name",
            headerName: "Status Name",
            flex: 1,
            cellClassName: "name-column--cell",
          },
          {
            field: "description",
            headerName: "Description",
            flex: 1,
            cellClassName: "name-column--cell",
          },
          {
            field: "id",
            headerName: "Remove",
            renderCell: Renderbin,
          }
          
        ];
      
        return (
          <Box m="20px">
            <Header title="User Defined Statuses" subtitle="UDS" />
            <Box>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
              onClick={() => { handleNewDevice() }}
            >            
            Add UDS
            </Button>
            </Box>
            <Box
              m="40px 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.orangeAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.grey[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.grey[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                  },
              }}
            >
              <DataGrid onRowClick={handleRowClick} {...data} rows={data} columns={columns} />
            </Box>
            <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{AlertName}</DialogTitle>
            <DialogContent>
            <TextField
            autoFocus
            margin="dense"
            value={FormName}
            onChange={(e) => setFormName(e.target.value)}
            label="Device Type"
            type="text"
            fullWidth
            variant="standard"
            />
            <TextField
            autoFocus
            margin="dense"
            value={FormDesc}
            onChange={(e) => setFormDesc(e.target.value)}
            label="Device Description"
            type="text"
            fullWidth
            variant="standard"
            />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleFormSubmit} sx={{
                    backgroundColor: colors.orangeAccent[600],
                    color: colors.grey[100],
                    fontSize: "12px",
                    fontWeight: "bold",
                    padding: "5px 5px",
                  }}>{AlertName}</Button>
              <Button onClick={handleClose} sx={{
                    backgroundColor: colors.blueAccent[600],
                    color: colors.grey[100],
                    fontSize: "12px",
                    fontWeight: "bold",
                    padding: "5px 5px",
                  }}>Cancel</Button>
            </DialogActions>
            </Dialog>
          </Box>
        );
}

export default UDSManager