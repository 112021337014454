import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import LanIcon from '@mui/icons-material/Lan';
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from "react-router-dom";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import EthMan from "./ethMan";
import Dot from "./dot";

const NetworkInfo = ({ netData, parent, reCharge }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const devices = netData;
  const [open, setOpen] = React.useState(false);
  const shapeStyles = { bgcolor: 'primary.main', width: 40, height: 40 };
  const shapeCircleStyles = { borderRadius: '50%' };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reCharge(true);
  };

  function rendernetwork() {
    if (devices.length === 0) {
       return (<Box
        display="grid"
        m="10px 40px 0 0"
        gridTemplateColumns="repeat(12, 1fr)"
        gap="10px"
        ><Box gridColumn="span 12" display="flex" alignItems="center" justifyContent="center" textAlign="center">
          <Typography
                sx={{ color: colors.orangeAccent[600] }}
              >No Network Interfaces Configured</Typography></Box></Box>
        )
    } else if (devices.length === 1) {
      return (<Box
        display="grid"
        m="10px 40px 0 0"
        gridTemplateColumns="repeat(12, 1fr)"
        gap="10px"
        ><Box gridColumn="span 12" display="flex" alignItems="center" justifyContent="center" textAlign="center">
          
          <Typography
                sx={{ color: colors.orangeAccent[600] }}
              >{devices[0].name}</Typography></Box>
              <Box gridColumn="span 12" display="flex" alignItems="center" justifyContent="center" textAlign="center">Active:<Dot value={devices[0].active} />&nbsp; DNS:<Dot value={devices[0].dns} />&nbsp;Monitor:<Dot value={devices[0].monitor} /></Box>
              <Box gridColumn="span 12" display="flex" alignItems="center" justifyContent="center" textAlign="center"><ul className="no-bullets">
          {devices[0].data && Object.keys(devices[0].data).map(key => {
          return (<li>
                  <span style={{ color: colors.orangeAccent[600] }}>{key}:</span> {devices[0].data[key]}        
                  </li>
          );
          })}
          </ul></Box>
              </Box>
        )
    } else {
      return (
          <Box
            display="grid"
            m="10px 40px 0 0"
            gridTemplateColumns="repeat(12, 1fr)"
            gap="5px"
            >
            <Box gridColumn="span 6" display="flex" alignItems="center" justifyContent="center" textAlign="center">
            <Typography
            sx={{ color: colors.orangeAccent[600] }}
            >
              {devices[0].name}
            </Typography>
          </Box>
          <Box gridColumn="span 6" display="flex" alignItems="center" justifyContent="center" textAlign="center">
          <Typography
          sx={{ color: colors.orangeAccent[600] }}
          >
          {devices[1].name}
          </Typography>
          </Box>
          <Box gridColumn="span 6" display="flex" alignItems="center" justifyContent="center" textAlign="center">Active:<Dot value={devices[0].active} />&nbsp; DNS:<Dot value={devices[0].dns} />&nbsp;Monitor:<Dot value={devices[0].monitor} /></Box>
          <Box gridColumn="span 6" display="flex" alignItems="center" justifyContent="center" textAlign="center">Active:<Dot value={devices[1].active} />&nbsp; DNS:<Dot value={devices[1].dns} />&nbsp;Monitor:<Dot value={devices[1].monitor} /></Box>
          <Box gridColumn="span 6" display="flex" alignItems="left" justifyContent="left" textAlign="left"><ul className="no-bullets">
          {devices[0].data && Object.keys(devices[0].data).map(key => {
          return (<li>
                  <span style={{ color: colors.orangeAccent[600] }}>{key}:</span> {devices[0].data[key]}        
                  </li>
          );
          })}
          </ul>
          </Box>
          <Box gridColumn="span 6" display="flex" alignItems="left" justifyContent="left" textAlign="left"><ul className="no-bullets">
          {devices[1].data && Object.keys(devices[1].data).map(key => {
          return (<li>
                  <span style={{ color: colors.orangeAccent[600] }}>{key}:</span> {devices[1].data[key]}        
                  </li>
          );
          })}
          </ul>
          </Box>
          </Box>
        )
    }
  }

  return (
    <Box width="100%" m="0 20px">
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.grey[100] }}
          >
          <LanIcon sx={{ color: colors.orangeAccent[600], fontSize: "26px" }} /> Network Configuration
          </Typography>
        </Box>
        <Box
          marginRight="40px"
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
        <Button variant="outlined" onClick={event =>handleClickOpen("0")} sx={{
                backgroundColor: colors.orangeAccent[700],
                color: colors.grey[100],
                fontSize: "10px",
                fontWeight: "bold",
                padding: "5px 5px",
              }}>
        <SettingsOutlinedIcon />
      </Button>
      </Box>
      </Box>
      {rendernetwork()}
      <Box display="flex" justifyContent="right" alignItems="center" mr="40px">
    
      <Dialog open={open} onClose={handleClose}
        maxWidth='lg'>
        <DialogTitle>Network Settings</DialogTitle>
          <EthMan netdata={devices} handleClose={handleClose} parent={parent}/>
      </Dialog>
      </Box>
    </Box>
  );
};

export default NetworkInfo;