import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import { Link } from "react-router-dom";
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { ReactDOM } from "react";

const DeviceNotes = ({ deviceInfo, setPageRefresh }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const notes = deviceInfo.notes_arr;
  const [open, setOpen] = React.useState(false);
  const [noteID, setNoteID] = React.useState();
  const [title, setTitle] = React.useState();
  const [details, setDetails] = React.useState();
  const [reload, setReload] = React.useState(false);

  const handleClickOpen = (nID) => {
    if (nID === 0) {
      setNoteID("0");
    } else {
      notes.map((child, i) => {
        if (child.id === nID) {
          setNoteID(nID);
          setTitle(child.name);
          setDetails(child.description);
        }
      }
      );
    }
    setOpen(true);
  };

  const handleUpdate = () => {
    setOpen(false);

    if (reload) {
      sendNote();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sendNote = async (np) => {
    var FormData = require('form-data');
    var data = new FormData();
    data.append('id', noteID);
    data.append('devid', deviceInfo.id);
    data.append('title', document.getElementById('NNTitle').value);
    data.append('text', document.getElementById('NNDetails').value);
    console.log(data);
    await axios.post('https://devices.elmodis.com/api/notes', data).then((res) => {
      setPageRefresh(true);
    })
}

  function renderChilds() {
    if (notes.length === 0) {
       return (
          <Typography
                sx={{ color: colors.orangeAccent[600] }}
              >No Notes</Typography>
        )
    } else {
      return (<Typography
      >{notes.map((child, i) =>
        <div><Typography style={{color: colors.orangeAccent[200], textDecoration: 'none', cursor: 'pointer'}} onClick={event =>handleClickOpen(child.id)}>- {child.name}</Typography></div>)}
        </Typography>
      )
    }
  }

  return (
    <Box width="100%" m="0 30px" display="flex" flexDirection="column" justifyContent="center" >
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.grey[100] }}
          >
          <EventNoteOutlinedIcon sx={{ color: colors.orangeAccent[600], fontSize: "26px" }} /> Maintenance Notes
          </Typography>
        </Box>
        <Box
          marginRight="40px"
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
        <Button variant="outlined" onClick={event =>handleClickOpen("0")} sx={{
                backgroundColor: colors.orangeAccent[700],
                color: colors.grey[100],
                fontSize: "10px",
                fontWeight: "bold",
                padding: "5px 5px",
              }}>
        ADD
      </Button>
      </Box>
      </Box>
        <Box display="flex" alignItems="left" justifyContent="left" textAlign="left" mt="10px">
        <Typography
            sx={{ color: colors.orangeAccent[600] }}
          >
        {renderChilds()}
          </Typography>
        </Box>
        <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { width: "30%" } }}>
        <DialogTitle><TextField
            autoFocus
            margin="dense"
            id="NNTitle"
            label="Note Name"
            onChange={e=>setReload(true)}
            type="text"
            defaultValue={title}
            fullWidth
            variant="standard"
          /></DialogTitle>
        <DialogContent>
        <TextField
          id="NNDetails"
          label="Note Details"
          onChange={e=>setReload(true)}
          rows={8}
          defaultValue={details}
          multiline
          fullWidth
          variant="standard"
        />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{
                backgroundColor: colors.orangeAccent[600],
                color: colors.grey[100],
                fontSize: "12px",
                fontWeight: "bold",
                padding: "5px 5px",
              }}>Cancel</Button>
              <Button sx={{
                backgroundColor: colors.orangeAccent[700],
                color: colors.grey[100],
                fontSize: "10px",
                fontWeight: "bold",
                padding: "5px 5px",
              }} onClick={handleUpdate}>Update</Button>
        
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DeviceNotes;