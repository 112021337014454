import React, { useContext, useState, useEffect } from "react"
import { useCookies } from "react-cookie";

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)

}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)
  const [authenticated, setauthenticated] = useState()
  const [cookies, setCookie] = useCookies(["token"]);

  function ping(token) {
    return 0
  }

  function login(obj) {
    setCurrentUser(obj)
    setauthenticated(true)
    setCookie("token", obj.token, {path: "/", expires: new Date(Date.now()+2592000)});
    return 0
  }

  function logout() {
    return 0
  }

  function resetPassword(email) {
    return 0
  }

  function updateEmail(email) {
    return 0
  }

  function updatePassword(password) {
    return 0
  }

  useEffect(() => {
    const unsubscribe = user => {
      setCurrentUser(user)
      setLoading(false)
    }

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    login,
    ping,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    authenticated
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}