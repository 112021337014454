import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";

const ErrMsg = ({ error, text}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  if (error) {
    return (
        <Typography color="#FF0000">{text}</Typography>
      );
  } else {
    return (
        <Typography>{text}</Typography>
      );
  }
  
};

export default ErrMsg;