import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";

const handleClientchange = async (id, cid) => {
    const obj = {};
    obj.action = 3;
    obj.id = id;
    obj.cid = cid;
    const request = await fetch('https://devices.elmodis.com/api/cc', {
    method: 'POST',
    headers: {
    'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
    })
  };

const ClientChanger = ({ devid, clients, current}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (<div className="udsselect">
  <select id="client" onChange={e=>handleClientchange(devid, e.target.value)} defaultValue={current}>
    {clients.map((uds, i) =>
    <option value={uds.pid}>{uds.name}</option>)}
  </select>
</div>)

  
};

export default ClientChanger;