import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../theme"
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { array } from "yup";
import Checkbox from '@mui/material/Checkbox';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useState, useRef } from "react";
import { useEffect } from "react";
import { createRoot } from "react-dom/client";


const EthMan = ({ netdata, handleClose, parent }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [devData, setDD] = React.useState(netdata);
  const [renderArea, setRArea] = useState();
  const [reload, setReload] = useState(false);
  const [newCnt, setCnt] = useState([]);
  const [newDevCnt, setNewDevCnt] = useState([]);
  const firstRef = useRef();

  const netDevices = {};
  console.log(devData);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  }));
  devData.map((nd, i) => {
    netDevices[nd.id] = {};
    netDevices[nd.id].monitor = nd.monitor;
    netDevices[nd.id].active = nd.active;
    netDevices[nd.id].dns = nd.dns;
    netDevices[nd.id].list = nd.list;
    netDevices[nd.id].name = nd.name;
    netDevices[nd.id].devid = nd.id;
    netDevices[nd.id].parent = parent;
  });

  const handleFormSubmit = async () => {
    const request = await fetch('https://devices.elmodis.com/api/eth', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(netDevices)
    })
    const result = await request.json();
    if(result.hasOwnProperty('Error')){
    } else {
      handleClose();
    }
  };

  function addDevice(){
    if (!newDevCnt[1]) {
      const tmp = newDevCnt;
      tmp[1] = 2;
      setNewDevCnt(tmp);
  } else {
      const tmp = newDevCnt;
      tmp[1] = tmp[1] + 1;
      setNewDevCnt(tmp);
  }
  setRArea(renderStuff())
  }

  function updateField(interfaceID, fieldID, fieldValue, single = false) {
    if (single){
      netDevices[interfaceID][fieldID] = fieldValue;
    } else {
      if (typeof netDevices[interfaceID]['data'] === 'undefined') {
        netDevices[interfaceID]['data'] = {};
      }
      netDevices[interfaceID]['data'][fieldID] = fieldValue;
    }
  }

  function updateCheckbox(interfaceID, fieldID, fieldValue) {
    if (netDevices[interfaceID][fieldID] === 1) {
      netDevices[interfaceID][fieldID] = 0;
    } else {
      netDevices[interfaceID][fieldID] = 1;
    }
  }

  function addChild(id){
    if (!newCnt[id]) {
        const tmp = newCnt;
        tmp[id] = 3;
        setCnt(tmp);
    } else {
        const tmp = newCnt;
        tmp[id] = tmp[id] + 1;
        setCnt(tmp);
    }
    setRArea(renderStuff());
  }

  function setChecked(inputVal,tt) {
    if (inputVal === 1) {
      return true
    } else {
      return false
    }
  }

  function renderStuff() {
    return (    
        <Box><DialogContent><Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={1}
        >
        {devData.map((nd, i) => 
        {
          console.log(nd);
            return <Grid item><form id={nd['name']}>
              <Item>
                <Typography
                  sx={{ color: colors.orangeAccent[600] }}
                ><TextField
                    autoFocus
                    margin="dense"
                    id="NNTitle"
                    label="Interface Name"
                    type="text"
                    onChange={e=>updateField(nd['id'],'name',e.target.value, true)}
                    defaultValue={nd['name']}
                    fullWidth
                    variant="standard" />
                    {e=>updateField(nd['id'],'name',nd['name'], true)}
                </Typography>
                Active:<Checkbox defaultChecked={setChecked(nd['active'], 'active')} onClick={e=>updateCheckbox(nd['id'],'active',e.target.value)}/>Monitor:<Checkbox defaultChecked={setChecked(nd['monitor'])} onClick={e=>updateCheckbox(nd['id'],'monitor',e.target.value)}/>DNS:<Checkbox defaultChecked={setChecked(nd['dns'])} onClick={e=>updateCheckbox(nd['id'],'dns',e.target.value)}/>List:<Checkbox defaultChecked={setChecked(nd['list'])} onClick={e=>updateCheckbox(nd['id'],'list',e.target.value)}/>
                <Box gridColumn="span 6" display="flex" alignItems="left" justifyContent="left" textAlign="left"><ul className="no-bullets">
                  {nd.data && Object.keys(nd.data).map((key, id) => {
                    return (<Box component="form"
                      sx={{
                        '& > :not(style)': { m: 1, width: '25ch' },
                      }}
                      noValidate
                      autoComplete="on"><TextField
                        autoFocus
                        margin="dense"
                        id={'key/' + nd.id + '/' + id}
                        type="text"
                        onChange={e=>updateField(nd['id'],'key/' + id ,e.target.value)}
                        defaultValue={key}
                        fullWidth
                        size="small"
                        variant="outlined"
                        color="warning" />{updateField(nd['id'],'key/' + id,key)}<TextField
                        autoFocus
                        margin="dense"
                        id={'value/' + nd.id + '/' + id}
                        onChange={e=>updateField(nd['id'],'value/' + id ,e.target.value)}
                        type="text"
                        size="small"
                        defaultValue={nd.data[key]}
                        fullWidth
                        variant="outlined" />{updateField(nd['id'],'value/' + id,nd.data[key])}</Box>
                    );
                  })}
                  {[...Array(newCnt[nd.id])].map((x, i) => {
                    if (i > 1) {
                      return (<Box component="form"
                        sx={{
                          '& > :not(style)': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="on"><TextField
                          autoFocus
                          margin="dense"
                          id={'nkey/' + nd.id + '/' + i}
                          onChange={e=>updateField(nd['id'],'nkey/' + i,e.target.value)}
                          type="text"
                          fullWidth
                          size="small"
                          variant="outlined"
                          color="warning" /><TextField
                          autoFocus
                          margin="dense"
                          id={'nvalue/' + nd.id + '/' + i}
                          onChange={e=>updateField(nd['id'],'nvalue/' + i,e.target.value)}
                          type="text"
                          size="small"
                          fullWidth
                          variant="outlined" /></Box>
                      );
                    }
                  })}
                  <Box fullWidth>
                    <Button onClick={e => addChild(nd.id)} sx={{
                      backgroundColor: colors.orangeAccent[600],
                      color: colors.grey[100],
                      fontSize: "12px",
                      fontWeight: "bold",
                      padding: "5px 5px",
                    }}
                      fullWidth><AddCircleOutlineOutlinedIcon /></Button></Box>
                </ul>
                </Box>
              </Item></form>
            </Grid>;
          }
        )}
        {/* ADD NEW DEVICE */}
        {[...Array(newDevCnt[1])].map((x, i) => 
        {
            console.log(i);
            const nid = 'nd'+i;
            if (i > 0) {
              netDevices[nid] = {};
              netDevices[nid]['nid'] = nid;
              netDevices[nid]['name'] = 'id';
              netDevices[nid]['device'] = parent;
            return <Grid item><form id={nid}>
              <Item>
                <Typography
                  sx={{ color: colors.orangeAccent[600] }}
                ><TextField
                    autoFocus
                    margin="dense"
                    id="NNTitle"
                    label="Interface Name"
                    onChange={e=>updateField(nid,'name',e.target.value, true)}
                    type="text"
                    fullWidth
                    variant="standard" />
                </Typography>
                Active:<Checkbox onClick={e=>updateCheckbox(nid,'active',e.target.value)}/>Monitor:<Checkbox onClick={e=>updateCheckbox(nid,'monitor',e.target.value)}/>DNS:<Checkbox onClick={e=>updateCheckbox(nid,'dns',e.target.value)}/>List:<Checkbox onClick={e=>updateCheckbox(nid,'list',e.target.value)}/>
                <Box gridColumn="span 6" display="flex" alignItems="left" justifyContent="left" textAlign="left"><ul className="no-bullets">
                  {[...Array(newCnt[nid])].map((x, z) => {
                    return (<Box component="form"
                      sx={{
                        '& > :not(style)': { m: 1, width: '25ch' },
                      }}
                      noValidate
                      autoComplete="on"><TextField
                        autoFocus
                        margin="dense"
                        id={'nkey/' + nid + '/' + z}
                        onChange={e=>updateField(nid,'nkey/' + i,e.target.value)}
                        type="text"
                        fullWidth
                        size="small"
                        variant="outlined"
                        color="warning" /><TextField
                        autoFocus
                        margin="dense"
                        id={'nvalue/' + nid + '/' + z}
                        onChange={e=>updateField(nid,'nvalue/' + i,e.target.value)}
                        type="text"
                        size="small"
                        fullWidth
                        variant="outlined" /></Box>
                    );
                  })}
                  <Box fullWidth>
                    <Button onClick={e => addChild(nid)} sx={{
                      backgroundColor: colors.orangeAccent[600],
                      color: colors.grey[100],
                      fontSize: "12px",
                      fontWeight: "bold",
                      padding: "5px 5px",
                    }}
                      fullWidth><AddCircleOutlineOutlinedIcon /></Button></Box>
                </ul>
                </Box>
              </Item></form>
            </Grid>;}}
          
        )}
        <Grid item >
        <Item>
        <Button onClick={e => addDevice()} sx={{
                backgroundColor: colors.orangeAccent[600],
                color: colors.grey[100],
                fontSize: "12px",
                fontWeight: "bold",
                padding: "5px 5px",
              }}><AddCircleOutlineOutlinedIcon /></Button>
        </Item>
        </Grid>
        </Grid>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleFormSubmit} sx={{
                backgroundColor: colors.orangeAccent[600],
                color: colors.grey[100],
                fontSize: "12px",
                fontWeight: "bold",
                padding: "5px 5px",
              }}>Confirm</Button>
          <Button onClick={handleClose} sx={{
                backgroundColor: colors.redAccent[700],
                color: colors.grey[100],
                fontSize: "12px",
                fontWeight: "bold",
                padding: "5px 5px",
              }}>Cancel</Button>
        </DialogActions></Box>
        );
  }

  useEffect(() => {
    const dupa = renderStuff();
    setRArea(dupa);
}, []);
    useEffect(() => {
    const dupa = renderStuff();
    setRArea(dupa);
    setReload(false);
    }, [reload]);

  return renderArea;

  
};

export default EthMan;