import { useState } from "react";
import { Routes, Route} from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Login from "./scenes/login";
import { useAuth } from "./contexts/AuthContext";
import { useCookies } from "react-cookie";
import Calendar from "./scenes/calendar/calendar";
import Manager from "./scenes/manager";
import Device from "./scenes/device/device"
import TypeManager from "./scenes/typesman/typesman"
import UDSManager from "./scenes/uds/uds"
import NetDeviceManager from "./scenes/netdevman/netdevman";
import Structures from "./scenes/Structures/structures";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const {authenticated, setauthenticated} = useAuth();
  const { login } = useAuth();
  const [cookies, setCookie] = useCookies(["token"]);
  const checkLogin = async (values) => {
    console.log(JSON.stringify(values));

    const request = await fetch('https://devices.elmodis.com/api/ping', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(values)
    })
    const result = await request.json();
    
    if(result.hasOwnProperty('Error')){

    } else {
      login(result)
    }

  };
  const TokenArray = {
    token: cookies.token
  };
if (!authenticated) {
  checkLogin(TokenArray)
    return (<ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Login />
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
    );
    } else {
    return (<ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/manager" element={<Manager />} />
              <Route path="/netman" element={<NetDeviceManager />} />
              <Route path="/structures" element={<Structures />} />
              <Route path="/device/*" element={<Device />} />
              <Route path="/types/*" element={<TypeManager />} />
              <Route path="/uds/*" element={<UDSManager />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
};
export default App;