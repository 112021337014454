import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import ProgressCircle from "./ProgressCircle";
import LanIcon from '@mui/icons-material/Lan';

const StatBox = ({ title, subtitle, icon, progress, increase }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width="100%" m="0 30px">
      <Box display="flex" justifyContent="space-between">
      <Box alignSelf="stretch">
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.grey[100] }}
          >
          {icon} {title}
          </Typography>
        </Box>
        <Box>
        
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mt="2px">
        <Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>
          Synced <br />
        </Typography>
        </Box>
        <Box
        display="grid"
        m="5px 0 0 0"
        gridTemplateColumns="repeat(12, 1fr)"
        gap="10px"
        >
      <Box gridColumn="span 8" display="flex" alignItems="center" justifyContent="center" textAlign="center">
        <Typography variant="h2" sx={{ color: colors.orangeAccent[500] }}>
          {increase}
        </Typography>
      </Box>
      <Box gridColumn="span 4" display="flex" alignItems="center" justifyContent="center" textAlign="center">
          <ProgressCircle progress={progress} />
        </Box>
    </Box>
    </Box>
  );
};

export default StatBox;