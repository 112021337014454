import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import { Link } from "react-router-dom";
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const DeviceEvents = ({ eventsData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState();
  const [details, setDetails] = React.useState();

  console.log(eventsData);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function renderEvents() {
    if (eventsData.length === 0) {
       return (
          <Typography
                sx={{ color: colors.orangeAccent[600] }}
              >No Events</Typography>
        )
    } else {
      return (<Typography
        sx={{ color: colors.orangeAccent[600] }}
      >{eventsData.map((child, i) =>
        <div><Link style={{color: colors.orangeAccent[200], textDecoration: 'none'}}>- {child.name}</Link></div>)}
        </Typography>
      )
    }
  }

  return (
    <Box width="100%" m="0 30px" display="flex" flexDirection="column" justifyContent="center" >
      <Box display="flex" justifyContent="flex-start">
        <Box>
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.grey[100] }}
          >
          <EventNoteOutlinedIcon sx={{ color: colors.orangeAccent[600], fontSize: "26px" }} /> Device Events
          </Typography>
        </Box>
        <Box>
        
        </Box>
      </Box>
        <Box display="flex" alignItems="left" justifyContent="left" textAlign="left" mt="20px">
        <Typography
            sx={{ color: colors.orangeAccent[600] }}
          >
        {renderEvents()}
          </Typography>
        </Box>
        <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Event Details</DialogTitle>
        <DialogContent>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{
                backgroundColor: colors.orangeAccent[600],
                color: colors.grey[100],
                fontSize: "12px",
                fontWeight: "bold",
                padding: "5px 5px",
              }}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DeviceEvents;