import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import PowerOffOutlinedIcon from '@mui/icons-material/PowerOffOutlined';

const OnlineBox = ({ title, subtitle, icon, status}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  function StatusIcon(status) {
    if (status === 1) {
        return (
            <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.greenAccent[100] }}
          >
          Online <PowerSettingsNewOutlinedIcon />
          </Typography>
        );
    } else if (status === 0) {
    return (
        <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.redAccent[400] }}
          >
          Offline <PowerOffOutlinedIcon />
          </Typography>
        );
    } else if (status === 2) {
      return (
          <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ color: colors.redAccent[400] }}
            >
            Not Enabled <PowerOffOutlinedIcon />
            </Typography>
          );
    }
  }

  

  return (
    <Box width="100%" m="0 30px">
      <Box display="flex" justifyContent="space-between">
      <Box alignSelf="stretch">
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.grey[100] }}
          >
          {icon} {title}
          </Typography>
        </Box>
        <Box>
        
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mt="2px">
        <Typography variant="h5" sx={{ color: colors.grey[200] }}>
          {subtitle} <br />
        </Typography>
        </Box>
        <Box
        display="grid"
        m="5px 0 0 0"
        gridTemplateColumns="repeat(12, 1fr)"
        gap="10px"
        >
      <Box gridColumn="span 12" display="flex" alignItems="center" justifyContent="center" textAlign="center">
        <Typography variant="h2" sx={{ color: colors.orangeAccent[500] }}>
          {StatusIcon(status)}
        </Typography>
      </Box>
    </Box>
    </Box>
  );
};

export default OnlineBox;