import { Typography, Box, IconButton, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { useState } from "react";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

const DevHead = ({devdata}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [edited, setEdited] = useState(false);
  const [name, setName] = useState(devdata.name);
  const [cname, setcName] = useState(devdata.name);

  const handleNameChange = async (id, name) => {
    const obj = {};
    obj.action = 3;
    obj.id = id;
    obj.name = name;
    const request = await fetch('https://devices.elmodis.com/api/nc', {
    method: 'POST',
    headers: {
    'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
    })
    setEdited(false);
    setcName(name);
  };

  if (edited) {

    return (
        <Box mb="30px">
          <Typography
            variant="h2"
            color={colors.grey[100]}
            fontWeight="bold"
            sx={{ m: "0 0 5px 0" }}
          >
            <Box
            display="inline-flex"
            backgroundColor={colors.primary[400]}
            borderRadius="3px"
            >
            <InputBase sx={{ ml: 2, flex: 1, fontSize: 28}} size="medium" placeholder={cname} defaultValue={cname} onChange={e=>setName(e.target.value)} />
            <IconButton type="button" sx={{ p: 1 }} onClick={e=>handleNameChange(devdata.id, name)}>
                <CheckRoundedIcon />
            </IconButton>
            <IconButton type="button" sx={{ p: 1 }} onClick={e=>setEdited(false)}>
                <HighlightOffRoundedIcon />
            </IconButton>
        </Box> {devdata.devid && <span>({devdata.devid})</span> }
          </Typography>
          <Typography variant="h5" color={colors.orangeAccent[400]}>
            {devdata.device_type}
          </Typography>
        </Box>
      );

  } else {

    return (
        <Box mb="30px">
          <Typography
            variant="h2"
            color={colors.grey[100]}
            fontWeight="bold"
            sx={{ m: "0 0 5px 0" }}
          >
            <span onClick={e=>setEdited(true)}>{cname}</span> {devdata.devid && <span>({devdata.devid})</span> }
          </Typography>
          <Typography variant="h5" color={colors.orangeAccent[400]}>
            {devdata.device_type}
          </Typography>
        </Box>
      );

  }
};
export default DevHead;